
:root {
  --heading-lg-font-size: #{vw(360, 36)};
  --heading-lg-font-weight: #{$font-weight-bold};
  --heading-lg-line-height: #{vw(360, 39)};
  --heading-lg-text-transform: uppercase;

  @include media(min-tablet) {
    --heading-lg-font-size: #{vw(1440, 60)};
    --heading-lg-line-height: #{vw(1440, 65)};
  }
}
