
:root {
  --body-lg-color: #{$color-body};
  --body-lg-font-family: #{$font-family-body};
  --body-lg-font-size: #{vw(360, 18)};
  --body-lg-font-weight: #{$font-weight-light};
  --body-lg-letter-spacing: normal;
  --body-lg-line-height: #{vw(360, 28)};
  --body-lg-text-transform: none;

  @include media(min-tablet) {
    --body-lg-font-size: #{vw(1440, 24)};
    --body-lg-line-height: #{vw(1440, 36)};
  }
}
