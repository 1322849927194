.nav {
  &__item {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $color-blue-darker;

    &:hover,
    &--active {
      color: darken($color-blue-light, 10%);
    }

    &:hover {
      transition: color 0.2s ease-in-out;
      will-change: color;
    }
  }

  &__lang {
    a {
      &::after {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }
  }

  .lang-item-en a::after {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCAzMCI+PGNsaXBQYXRoIGlkPSJwcmVmaXhfX2EiPjxwYXRoIGQ9Ik0wIDB2MzBoNjBWMHoiLz48L2NsaXBQYXRoPjxjbGlwUGF0aCBpZD0icHJlZml4X19iIj48cGF0aCBkPSJNMzAgMTVoMzB2MTV6djE1SDB6SDBWMHpWMGgzMHoiLz48L2NsaXBQYXRoPjxnIGNsaXAtcGF0aD0idXJsKCNwcmVmaXhfX2EpIj48cGF0aCBkPSJNMCAwdjMwaDYwVjB6IiBmaWxsPSIjMDEyMTY5Ii8+PHBhdGggZD0iTTAgMGw2MCAzMG0wLTMwTDAgMzAiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSI2Ii8+PHBhdGggZD0iTTAgMGw2MCAzMG0wLTMwTDAgMzAiIGNsaXAtcGF0aD0idXJsKCNwcmVmaXhfX2IpIiBzdHJva2U9IiNDODEwMkUiIHN0cm9rZS13aWR0aD0iNCIvPjxwYXRoIGQ9Ik0zMCAwdjMwTTAgMTVoNjAiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxMCIvPjxwYXRoIGQ9Ik0zMCAwdjMwTTAgMTVoNjAiIHN0cm9rZT0iI0M4MTAyRSIgc3Ryb2tlLXdpZHRoPSI2Ii8+PC9nPjwvc3ZnPg==');
  }

  .lang-item-nl a::after {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5IDYiPjxwYXRoIGZpbGw9IiMyMTQ2OEIiIGQ9Ik0wIDBoOXY2SDB6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTAgMGg5djRIMHoiLz48cGF0aCBmaWxsPSIjQUUxQzI4IiBkPSJNMCAwaDl2MkgweiIvPjwvc3ZnPg==');
  }
}

// Mobile styles
@include media(max-tablet) {
  .nav {
    @include hide-scrollbar;

    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.83, 0, 0.17, 1);
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);

    &__item {
      padding: vw(375, 16) 0;
      width: 100%;
      font-size: vw(375, 20);
      line-height: vw(375, 20);
      text-align: center;
      font-weight: $font-weight-bold;
      opacity: 0;
      transform: translateY(vw(350, 100));
      transition: all 0.15s cubic-bezier(0.83, 0, 0.17, 1);
    }

    &__lang {
      display: flex;
      justify-content: center;
      margin-top: vw(360, 20);
      width: 100%;
      opacity: 0;
      transform: translateY(vw(350, 10));
      transition: all 1.25s $ease-out-expo;

      li {
        display: flex;

        a {
          position: relative;
          z-index: 2;
          width: vw(360, 30);
          height: vw(360, 20);
          overflow: hidden;
          font-size: 1px;
          border-radius: vw(360, 5);
          outline: vw(360, 1.5) solid $color-blue-darker;

          &::after {
            width: vw(360, 30);
            height: vw(360, 20);

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        }
      }
    }
  }

  .nav-open {
    .nav {
      visibility: visible;
      opacity: 1;
      transition: all 0.35s cubic-bezier(0.83, 0, 0.17, 1);
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);

      @for $i from 0 through 15 {
        &__item:nth-child(#{$i + 1}) {
          transition-delay: 0.05s * $i + 0.15;
        }
      }

      &__item {
        opacity: 1;
        transform: translateY(0);
        transition: all 1.25s $ease-out-expo;
      }

      &__lang {
        opacity: 1;
        transform: translateY(0);
        transition: all 2s $ease-out-expo;
        transition-delay: 1s;
      }
    }

    &.app {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
  }
}

// Desktop styles
@include media(min-tablet) {
  .nav {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 1;

    &__item {
      display: flex;
      align-items: center;
      position: relative;

      margin: 0 vw(1440, 15);

      font-size: vw(1440, 18);
      line-height: vw(1440, 18);

      transition: all 1.25s $ease-out-expo;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__lang {
      display: flex;
      align-items: stretch;

      li {
        display: flex;
        align-items: center;
        margin-left: vw(1440, 15);

        a {
          position: relative;
          z-index: 2;
          width: vw(1440, 30);
          height: vw(1440, 20);
          overflow: hidden;
          border-radius: vw(1440, 5);
          outline: 1.5px solid $color-blue-darker;

          &::after {
            width: vw(1440, 30);
            height: vw(1440, 20);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        }
      }
    }
  }
}
