html {
  scroll-behavior: smooth;

  ::-moz-selection {
    color: $color-selection-color;
    background: $color-selection-background;
  }

  ::selection {
    color: $color-selection-color;
    background: $color-selection-background;
  }
}

body {
  font-family: $font-family-body;
  color: $color-body;
  background-color: $color-white;
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
  }
}

address {
  font-style: normal;
}

.wave {
  position: absolute;
  top: vw(1440, -64);
  z-index: 0;
  height: vw(1440, 65);
  width: 100%;
}

.wave-mask {
  position: absolute;
  top: vw(1440, 0);
  z-index: 9;
  //height: vw(1440, 65);
  width: 100%;
}

.is-dark + .banner .wave-mask path {
  fill: $color-blue-darker;
}

.muted {
  opacity: 0.8;
}

.is-light .wave {
  z-index: 4;
  top: calc(-4.4444444444vw);
}

.is-light + .is-light .wave {
  display: none;
}

.is-dark + .is-dark .wave {
  display: none;
}

.is-light + .is-light {
  padding-top: 0 !important;
}

.page {
  @include container;
  @include component('sm','light');

  &__wrap {
    grid-column: 3 / 23;

    @include media(min-tablet) {
      grid-column: 5 / 21;
    }
  }
}
