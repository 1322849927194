
:root {
  --heading-xl-font-size: #{vw(360, 32)};
  --heading-xl-line-height: #{vw(360, 36)};
  --heading-xl-font-weight: #{$font-weight-bold};
  --heading-xl-text-transform: uppercase;

  @include media(min-tablet) {
    --heading-xl-font-size: #{vw(1440, 66)};
    --heading-xl-line-height: #{vw(1440, 72)};
  }
}
