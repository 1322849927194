.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  position: fixed;
  z-index: 100;
  overflow: hidden;
  border-radius: var(--border-radius-lg);

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
  }

  @include media(max-tablet) {
    align-items: center;
    top: calc($col-1 / 2);
    right: calc($col-1 / 2);
    left: calc($col-1 / 2);
    padding: 0 vw(360, 15);
    height: vw(360, 50);
  }

  @include media(min-tablet) {
    top: vw(1440, 40);
    right: $col-1;
    left: $col-1;
    padding: 0 vw(1440, 30);
    height: vw(1440, 70);
  }
}
