// Shared
.cards {
  @include component('md','dark');

  background-color: $color-blue-darker;

  &__intro {
    @include container;

    margin-bottom: vw(360, 70);
    text-align: center;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 70);
    }
  }

  &__heading,
  &__body {
    grid-column: 3 / 23;

    @include media(min-tablet) {
      grid-column: 5 / 21;
    }
  }

  &__heading {
    @include type(heading-lg);

    margin-bottom: vw(360, 30);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 30);
    }
  }

  &__body {
    @include type(body-lg);
  }

  &__container {
    // Mobile
    @include media(max-tablet) {
      display: grid;
      grid-gap: calc(vw(360, 30) / 2);
      grid-template-columns: vw(360, 10) repeat(var(--total), calc(100% - vw(360, 30) * 2)) vw(360, 10);
      grid-template-rows: minmax(vw(360, 150), 1fr);
      overflow-x: scroll;
      scroll-snap-type: x proximity;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      &::before,
      &::after {
        content: '';
      }
    }

    // Desktop
    @include media(min-tablet) {
      grid-template-columns: repeat(3, 1fr);
      display: grid;
      grid-gap: vw(1440, 25);
      padding-left: vw(1440, 25);
      padding-right: vw(1440, 25);

      &.has-1 {
        grid-template-columns: repeat(1, vw(1440, 588));
        justify-content: center;
      }

      &.has-2 {
        grid-template-columns: repeat(2, 1fr);
        padding: 0 vw(1440, 120);
      }
    }
  }
}

.card {
  // Mobile
  @include media(max-tablet) {
    scroll-snap-align: center;
  }

  display: flex;
  align-items: flex-end;
  position: relative;
  min-height: vw(360, 420);
  overflow: hidden;
  text-align: left;
  background-color: white;
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;

  @include media(min-tablet) {
    min-height: vw(1440, 600);
  }

  &__heading {
    @include type(heading-sm);

    margin-bottom: vw(360, 15);
    text-transform: uppercase;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 15);
    }
  }

  &__body {
    @include type(body-sm);
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__button {
    display: inline-block;
    margin-top: vw(360, 30);

    @include media(min-tablet) {
      margin-top: vw(1440, 50);
    }
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding: vw(360, 75) vw(360, 15) 0 vw(360, 20);
    background: linear-gradient(0deg, rgba(7, 17, 55, 1) 25%, rgba(7, 17, 55, 0) 100%);

    @include media(min-tablet) {
      padding: vw(1440, 200) vw(1440, 40) 0 vw(1440, 40);
    }
  }
}
