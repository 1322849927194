
:root {
  --heading-md-font-size: #{vw(360, 38)};
  --heading-md-font-weight: $font-weight-bold;
  --heading-md-line-height: #{vw(360, 46)};
  --heading-md-text-transform: none;

  @include media(min-tablet) {
    --heading-md-font-size: #{vw(1440, 50)};
    --heading-md-line-height: #{vw(1440, 65)};
  }
}
