
:root {
  // --body-xl-color: #{$color-body};
  // --body-xl-font-family: #{$font-family-body};
  --body-xl-font-size: #{vw(360, 17)};
  --body-xl-font-weight: #{$font-weight-light};
  // --body-xl-letter-spacing: normal;
  --body-xl-line-height: #{vw(360, 26)};
  // --body-xl-text-transform: none;

  @include media(min-tablet) {
    --body-xl-font-size: #{vw(1440, 24)};
    --body-xl-line-height: #{vw(1440, 36)};
  }
}
