.vacancies {
  @include container;
  @include component('md','light');

  &__intro {
    grid-column: 3 / 23;
    margin-bottom: vw(360, 70);

    @include media(min-tablet) {
      grid-column: 5 / 21;
      margin-bottom: vw(1440, 70);
    }
  }

  &__heading {
    @include type(heading-lg);

    margin-bottom: vw(360, 30);
    color: $color-blue-dark;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 30);
    }
  }

  &__body {
    @include type(body-lg);

    color: $color-grey-dark;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    grid-column: 2 / 24;
    gap: vw(360, 10);

    @include media(min-tablet) {
      grid-column: 4 / 22;
      gap: vw(1440, 10);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: vw(360, 20) vw(360, 20);
    background-color: $color-grey-lighter;
    border-radius: var(--border-radius-lg);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    will-change: background-color, color;
    gap: vw(360, 10);

    @include media(min-tablet) {
      padding: vw(1440, 20) vw(1440, 60);
      gap: vw(1440, 10);
    }

    &:hover {
      background-color: darken($color-grey-lighter, 2%);
      transition: background-color 0.3s ease-in-out, color 0.15s ease-in-out;
    }
  }

  &__title {
    @include type(heading-sm);

    text-transform: uppercase;
    color: $color-heading-dark;
  }

  &__details {
    @include type(body-xs);

    display: grid;
    grid-gap: vw(360, 10);
    margin: 0;
    padding: 0;
    color: $color-body-dark;
    list-style: none;

    @include media(max-tablet) {
      margin-top: vw(360, 5);
    }

    @include media(min-tablet) {
      grid-template-columns: 30fr 20fr 30fr 20fr;
      grid-gap: vw(1440, 10);
    }

    li {
      display: flex;
      align-content: center;
      gap: vw(360, 10);

      @include media(min-tablet) {
        gap: vw(1440, 10);
      }
    }
  }

  &__icon {
    height: vw(360, 23);
    width: vw(360, 22);

    @include media(min-tablet) {
      height: vw(1440, 25);
      width: vw(1440, 24);
    }
  }
}
