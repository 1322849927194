
:root {
  --body-xs-color: #{$color-body};
  --body-xs-font-family: #{$font-family-body};
  --body-xs-font-size: #{vw(360, 15)};
  --body-xs-font-weight: #{$font-weight-light};
  --body-xs-letter-spacing: normal;
  --body-xs-line-height: #{vw(360, 20)};
  --body-xs-text-transform: none;

  @include media(min-tablet) {
    --body-xs-font-size: #{vw(1440, 16)};
    --body-xs-line-height: #{vw(1440, 26)};
  }
}
