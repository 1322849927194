.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: vw(360, 30);
  gap: vw(360, 30);

  @include media(min-tablet) {
    padding-bottom: vw(1440, 30);
    gap: vw(1440, 30);
  }

  &__copy {
    @include type(body-xs);

    color: $color-grey-light;
  }

  &__tommy-logo {
    width: vw(360, 70);
    height: vw(360, 30);

    path {
      transition: fill 0.2s ease-in-out;
      will-change: fill;
      fill: #060f2926;
    }

    @include media(min-tablet) {
      width: vw(1440, 70);
      height: vw(1440, 30);
    }
  }

  &__tommy-link {
    display: inline-block;

    &:hover .footer__tommy-logo path {
      transition: fill 0.3s ease-in-out;
      fill: #060f2940;
    }
  }
}
