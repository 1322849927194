.contact {
  @include container;
  @include component('md','light');

  &__heading {
    @include type(heading-lg);

    grid-column: 2 / 24;
    margin-bottom: vw(360, 30);
    color: $color-blue-dark;
    text-align: center;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 60);
    }
  }

  &__map,
  &__address,
  &__columns {
    grid-column: 3 / 23;
  }

  &__map {
    height: vw(360, 300);
    overflow: hidden;
    border-radius: var(--border-radius-lg);

    @include media(min-tablet) {
      height: vw(1440, 400);
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__address {
    @include type(body-lg);

    margin-top: vw(360, 15);
    color: $color-blue-darker;

    @include media(min-tablet) {
      margin-top: vw(1440, 30);
    }
  }

  &__columns {
    grid-column: 2 / 24;

    @include media(max-tablet) {
      display: flex;
      flex-direction: column;
      margin-top: vw(360, 50);
      text-align: center;
      gap: vw(360, 30);
    }

    @include media(min-tablet) {
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(3, 1fr);
      margin-top: vw(1440, 70);
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include media(min-tablet) {
      gap: vw(1440, 10);
    }
  }

  &__title {
    @include type(heading-sm);

    margin-bottom: vw(360, 10);
    color: $color-blue-dark;
    text-transform: uppercase;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 20);
    }
  }

  &__link {
    @include type(body-xl);
  }

  &__social {
    display: flex;
    justify-content: center;
    gap: vw(1440, 30);
  }

  &__icon {
    &:hover path {
      fill: $color-link-hover;
    }
  }

  &__linkedin,
  &__youtube,
  &__facebook {
    height: vw(360, 35);

    @include media(min-tablet) {
      height: vw(1440, 40);
    }
  }
}
