// Typography mixin
// How to use:
// 1. duplicate global/type/_*.scss
// 2. change values therein
// 3. when applying, use @typo(name) (inside breakpoint is fine)
// CSS rules that are invalid (because they don't exist) are ignored by the browser.

@mixin type($name: '') {
  // margin-bottom: var(--#{$name}-margin-bottom);
  // margin-top: var(--#{$name}-margin-top);
  // color: var(--#{$name}-color);
  // font-family: var(--#{$name}-font-family);
  font-size: var(--#{$name}-font-size);
  // font-style: var(--#{$name}-font-style);
  // font-variant: var(--#{$name}-font-variant);
  font-weight: var(--#{$name}-font-weight);
  // letter-spacing: var(--#{$name}-letter-spacing);
  line-height: var(--#{$name}-line-height);
  text-transform: var(--#{$name}-text-transform);
  // text-align: var(--#{$name}-text-align);
}
