.error-404 {
  &__container {
    @include component;
    @include container;

    padding-top: vw(360, 170);
    margin-top: 0;
    text-align: center;

    @include media(min-tablet) {
      padding-top: vw(1440, 235);
    }
  }

  &__heading,
  &__button {
    grid-column: 3 / 23;
  }

  &__heading {
    @include type(heading-md);

    margin-bottom: vw(360, 40);
    color: $color-blue-darker;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 40);
    }
  }
}
