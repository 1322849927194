.header {
  @include container;

  padding-top: vw(360, 120);
  background: $color-blue-dark;
  background: radial-gradient(circle, $color-blue-dark 0%, $color-blue-darker 100%);

  @include media(min-tablet) {
    padding-top: vw(1440, 220);
  }

  &.has-image {
    position: relative;
    padding-bottom: vw(360, 100);

    @include media(min-tablet) {
      padding-bottom: vw(1440, 220);
    }

    + section {
      padding-top: vw(360, 160) !important;

      @include media(min-tablet) {
        padding-top: vw(1440, 340) !important;
      }

      // .wave {
      //   @include media(min-tablet) {
      //     top: vw(1440, -264);
      //   }
      // }
    }
  }

  &__heading {
    @include type(heading-xl);

    color: $color-heading-light;

    span {
      color: lighten($color-blue-light, 5%);
    }
  }

  &--home &__heading {
    grid-column: 3 / 23;
    margin-bottom: vw(360, 20);

    @include media(min-tablet) {
      grid-column: 5 / 21;
      margin-bottom: vw(1440, 30);
    }
  }

  &--page &__heading {
    @include type(heading-lg);

    grid-column: 3 / 23;
    margin-bottom: vw(360, 80);

    @include media(min-tablet) {
      grid-column: 5 / 21;
      margin-bottom: vw(1440, 120);
    }
  }

  &__body {
    @include type(body-xl);

    grid-column: 4 / 23;

    @include media(min-tablet) {
      grid-column: 6 / 20;
    }
  }

  &__visual {
    position: absolute;
    bottom: vw(360, -110);
    left: $col-1;
    right: $col-1;
    z-index: 5;
    overflow: hidden;
    border-radius: var(--border-radius-lg);

    @include media(min-tablet) {
      bottom: vw(1440, -260);
      left: $col-3;
      right: $col-3;
      --aspect-ratio: 2.8 !important;
    }
  }
}

// .headroom {
//   right: vw(360, 15);
//   will-change: top;
//   transition: top 0.35s $ease-in-back;

//   &--unpinned {
//     position: fixed;
//     top: vw(360, -50);
//     transition-delay: 0.45s;
//   }

//   &--pinned,
//   &--bottom {
//     position: fixed;
//     top: vw(360, 24);
//     transition: top 0.5s $ease-out-back;
//   }

//   &--top {
//     position: absolute;
//     top: vw(360, 24);
//   }
// }
