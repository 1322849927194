
:root {
  --heading-sm-font-size: #{vw(360, 21)};
  --heading-sm-font-weight: #{$font-weight-bold};
  --heading-sm-line-height: #{vw(360, 28)};
  --heading-sm-text-transform: none;

  @include media(min-tablet) {
    --heading-sm-font-size: #{vw(1440, 28)};
    --heading-sm-line-height: #{vw(1440, 32)};
  }
}
