
:root {
  --paragraph-font-family: #{$font-family-body};
  --paragraph-font-size: #{vw(360, 16)};
  --paragraph-font-weight: #{$font-weight-light};
  --paragraph-line-height: #{vw(360, 25)};
  --paragraph-text-transform: none;

  @include media(min-tablet) {
    --paragraph-font-size: #{vw(1440, 20)};
    --paragraph-line-height: #{vw(1440, 32)};
  }
}
