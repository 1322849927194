// .richtext {
//   color: $color-grey-dark;

//   p {
//     @include type(body-sm);
//   }

//   ul {
//     padding-left: 0;
//     list-style: none;
//     @include type(body-sm);

//     @include media(min-tablet) {
//       padding-left: vw(1440, 40);
//     }

//     li {
//       position: relative;
//       padding-left: vw(360, 35);

//       @include media(min-tablet) {
//         padding-left: vw(1440, 35);
//       }

//       &::before {
//         content: '';
//         top: vw(360, 12);
//         left: 0;
//         position: absolute;
//         height: vw(360, 2);
//         width: vw(360, 20);
//         background: $color-list-bullet;
//         border-radius: vw(360, 2);

//         @include media(min-tablet) {
//           top: vw(1440, 12);
//           height: vw(1440, 2);
//           width: vw(1440, 20);
//           border-radius: vw(1440, 2);
//         }
//       }
//     }
//   }

//   ol {
//     padding-left: vw(360, 65);
//     list-style: decimal;

//     @include media(min-tablet) {
//       padding-left: vw(1440, 65);
//     }

//     li {
//       padding-left: vw(360, 10);

//       @include media(min-tablet) {
//         padding-left: vw(1440, 10);
//       }
//     }
//   }

//   ul,
//   ol {
//     @include type(list);

//     li + li {
//       margin-top: vw(360, 8);

//       @include media(min-tablet) {
//         margin-top: vw(1440, 8);
//       }
//     }
//   }

//   h3,
//   h4 {
//     @include type(heading-sm);

//     margin-top: vw(360, 50);

//     @include media(min-tablet) {
//       margin-top: vw(1440, 50);
//     }
//   }

//   h3 + p,
//   h4 + p {
//     margin-top: vw(360, 30);

//     @include media(min-tablet) {
//       margin-top: vw(1440, 30);
//     }
//   }

//   p + p {
//     margin-top: vw(360, 25);

//     @include media(min-tablet) {
//       margin-top: vw(1440, 25);
//     }
//   }

//   p + ul,
//   p + ol {
//     margin-top: vw(360, 30);

//     @include media(min-tablet) {
//       margin-top: vw(1440, 30);
//     }
//   }

//   ul + p,
//   ol + p {
//     margin-top: vw(360, 30);

//     @include media(min-tablet) {
//       margin-top: vw(1440, 30);
//     }
//   }

//   a {
//     color: $color-link;
//     -webkit-text-decoration-color: $color-link-underline; /* Safari */
//     text-decoration-color: $color-link-underline;
//     will-change: color;
//     transition: color 0.2s ease-in-out;
//     text-decoration-thickness: vw(360, 1.5);

//     @include media(min-tablet) {
//       text-decoration-thickness: vw(1440, 1.5);
//     }

//     &:hover {
//       color: $color-link-hover;
//       transition: color 0.3s ease-in-out;
//     }
//   }
// }

.richtext {
  h2,
  h3,
  h4 {
    @include type(heading-sm);

    margin-bottom: vw(360, 15);
    color: $color-blue-darker;

    @include media(min-tablet) {
      margin-bottom: vw(1440, 15);
    }
  }

  + ul,
  ol {
    margin-top: 0;
  }

  p,
  ul,
  ol {
    @include type(body-sm);

    color: $color-grey-dark;
  }

  p {
    margin-bottom: vw(360, 35);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 35);
    }
  }

  ul,
  ol {
    margin-top: vw(360, 35);
    margin-bottom: vw(360, 35);
    padding-left: vw(360, 35);
    list-style-type: disc;

    @include media(min-tablet) {
      margin-top: vw(1440, 45);
      margin-bottom: vw(1440, 45);
      padding-left: vw(1440, 40);
    }

    li {
      margin-bottom: vw(360, 3);

      @include media(min-tablet) {
        margin-bottom: vw(1440, 5);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
