:root {
  --sm-component-spacing: #{vw(360, 50)};
  --md-component-spacing: #{vw(360, 100)};
  // --lg-component-spacing: #{vw(360, 190)};

  @include media(min-tablet) {
    --sm-component-spacing: #{vw(1440, 100)};
    --md-component-spacing: #{vw(1440, 200)};
    // --lg-component-spacing: #{vw(1440, 280)};
  }
}

@mixin component($size: 'md', $theme: 'dark') {
  position: relative;
  width: 100%;
  height: auto;

  @if $theme == 'light' {
    padding-top: var(--#{$size}-component-spacing);
    padding-bottom: var(--#{$size}-component-spacing);
  }

  @if $theme == 'dark' {
    padding-top: var(--#{$size}-component-spacing);
    padding-bottom: var(--#{$size}-component-spacing);
  }
}
