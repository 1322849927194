
:root {
  --body-sm-color: #{$color-body};
  --body-sm-font-family: #{$font-family-body};
  --body-sm-font-size: #{vw(360, 15)};
  --body-sm-font-weight: #{$font-weight-light};
  --body-sm-letter-spacing: normal;
  --body-sm-line-height: #{vw(360, 23)};
  --body-sm-text-transform: none;

  @include media(min-tablet) {
    --body-sm-font-size: #{vw(1440, 18)};
    --body-sm-line-height: #{vw(1440, 28)};
  }
}
