.logo {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-right: auto;

  @include media(max-tablet) {
    &__img {
      height: vw(360, 15);
    }
  }

  @include media(min-tablet) {
    &__img {
      height: vw(1440, 20);
    }
  }
}
