// Colors
$color-white: #fff;
$color-blue-darker: #071137;
$color-blue-dark: #26315c;
$color-blue-light: #4bb2e5;
$color-grey-dark: #767983;
$color-grey-light: #b9becb;
$color-grey-lighter: #f5f8ff;

// Theming
$color-selection-color: $color-white;
$color-selection-background: $color-blue-light;

$color-body-background: $color-white;
$color-body: $color-white;

$color-link: $color-blue-light;
$color-link-underline: $color-blue-light;
$color-link-hover: darken($color-blue-light, 15%);
$color-list-bullet: $color-blue-light;

$color-button-background: $color-blue-light;
$color-button-border: $color-blue-light;
$color-button-background-hover: lighten($color-blue-light, 7%);
$color-button-border-hover: $color-blue-light;

$color-heading-dark: $color-blue-dark;
$color-heading-light: $color-white;
$color-body-dark: $color-grey-dark;
$color-body-light: $color-white;

// Fonts
$font-family-body: 'Poppins', helvetica, arial, sans-serif;
$font-family-heading: 'Poppins', helvetica, arial, sans-serif;

// Type
$font-weight-black: 800;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-extra-light: 200;

// Spacings
$col-1: calc(100vw / 24 * 1);
$col-2: calc(100vw / 24 * 2);
$col-3: calc(100vw / 24 * 3);
$col-4: calc(100vw / 24 * 4);
$col-5: calc(100vw / 24 * 5);
$col-6: calc(100vw / 24 * 6);
$col-7: calc(100vw / 24 * 7);
$col-8: calc(100vw / 24 * 8);
$col-9: calc(100vw / 24 * 9);
$col-10: calc(100vw / 24 * 10);
$col-11: calc(100vw / 24 * 11);
$col-12: calc(100vw / 24 * 12);
$col-13: calc(100vw / 24 * 13);
$col-14: calc(100vw / 24 * 14);
$col-15: calc(100vw / 24 * 15);
$col-16: calc(100vw / 24 * 16);
$col-17: calc(100vw / 24 * 17);
$col-18: calc(100vw / 24 * 18);
$col-19: calc(100vw / 24 * 19);
$col-20: calc(100vw / 24 * 20);
$col-21: calc(100vw / 24 * 21);
$col-22: calc(100vw / 24 * 22);
$col-23: calc(100vw / 24 * 23);
$col-24: calc(100vw / 24 * 24);

// Easings
$ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
$ease-in-back: cubic-bezier(0.36, 0, 0.66, -0.56);
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
$ease-in-expo: cubic-bezier(0.7, 0, 0.84, 0);
$ease-in-quart: cubic-bezier(0.5, 0, 0.75, 0);

:root {
  --border-radius-lg: #{vw(360, 10)};
  --border-radius-md: #{vw(360, 5)};

  @include media(min-tablet) {
    --border-radius-lg: #{vw(1440, 20)};
    --border-radius-md: #{vw(1440, 10)};
  }
}
