.banner {
  @include component('md','dark');

  background-color: $color-blue-darker;

  &__image,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &::after {
    content: '';
    z-index: 1;
    background: linear-gradient(180deg, rgba(7, 17, 55, 0.95) 50%, rgba(7, 17, 55, 0.25) 100%);

    @include media(min-tablet) {
      background: linear-gradient(90deg, rgba(7, 17, 55, 0.9) 20%, rgba(7, 17, 55, 0.05) 100%);
    }
  }

  &__image {
    z-index: 0;
    object-fit: cover;
  }

  &__container {
    @include container;
  }

  &__text {
    grid-column: 3 / 23;
    position: relative;
    z-index: 2;

    @include media(min-tablet) {
      grid-column: 3 / 15;
    }
  }

  &__heading {
    @include type(heading-lg);

    margin-bottom: vw(360, 20);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 20);
    }
  }

  &__body {
    @include type(body-lg);
  }

  &__button {
    display: inline-block;
    margin-top: vw(360, 30);

    @include media(min-tablet) {
      margin-top: vw(1440, 30);
    }
  }
}
