.button {
  display: inline-flex;
  align-content: center;
  padding: 0 vw(360, 20);
  height: vw(360, 50);
  white-space: nowrap;
  line-height: vw(360, 50);
  font-size: vw(360, 14);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-white;
  background: $color-button-background;
  border: vw(360, 0) solid $color-button-border;
  border-radius: var(--border-radius-md);
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  will-change: background-color, color;

  @include media(min-tablet) {
    padding: 0 vw(1440, 30);
    height: vw(1440, 50);
    font-size: vw(1440, 15);
    line-height: vw(1440, 50);
    border: vw(1440, 0) solid $color-button-border;
  }

  &:hover {
    color: $color-white;
    background: $color-button-background-hover;
    transition: background-color 0.3s ease-in-out, color 0.15s ease-in-out;
  }
}
