.text {
  @include container;
  @include component('md','light');

  &__heading {
    @include type(heading-lg);

    grid-column: 3 / 19;
    margin-bottom: vw(360, 20);
    color: $color-heading-dark;

    @include media(min-tablet) {
      grid-column: 3 / 19;
      margin-bottom: vw(1440, 35);
    }
  }

  &__heading,
  &__column {
    grid-column: 3 / 23;
  }

  @include media(max-tablet) {
    &.is-double &__column.is-first {
      margin-bottom: vw(360, 20);
    }
  }

  @include media(min-tablet) {
    &.is-single &__heading,
    .is-single &__column {
      grid-column: 4 / 22;
    }

    &.is-double &__column.is-first {
      grid-column: 4 / 13;
    }

    &.is-double &__column.is-second {
      grid-column: 14 / 23;
    }
  }

  &__column {
    color: $color-body-dark;
  }
}
